<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.title')"
              label-for="title"
            >
              <b-form-input
                id="name"
                :value="searchFields.title"
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.type') }}</label>
            <v-select
              v-model="searchFields.model"
              :dir="$store.state.appConfig.layout.direction"
              :options="types"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='statuses'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"types"'

    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [listMixin],
  computed: {
    statuses() {
      return [
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
      ]
    },
  },

  data() {
    return {
      resourceName: 'type-category',
      singularName: 'type-category',
      searchFields: {
        title: null,
        model: null,
        status: null,
      },
      types:[],
    }
  },

  created() {
    this.axios.get(`/model-options/type-parent-category`)
      .then(res => {
        this.types = res.data.data
       })
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },

}
</script>
